import React from "react"
import { Router } from "@reach/router"
import { login, logout, isAuthenticated, getProfile } from "../utils/auth"
import { makeStyles} from '@material-ui/core/styles';
import { navigate } from "gatsby"

import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import ButtonBase from '@material-ui/core/ButtonBase'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Loading from '../components/auth0/loading'


const Home = ({ user }) => {
  return(
    <section>
        Bonjour, {user.name ? user.name : "Bienvenu ! "}!
    </section>
  );
}


const useStyles = makeStyles((theme) => ({
    root: {
      // display: 'flex',
      // flexWrap: 'wrap',
      textAlign: 'center',
      marginTop: '2%',
      marginBottom: '5%',
      fontFamily: 'font-family: system-ui',
      fontSize: '1.5rem',
      },
      image: {
      position: 'relative',
      height: 300,
      marginTop: '2%',
      [theme.breakpoints.down('xs')]: {
        width: '100% !important', // Overrides inline-style
        height: 100,
        },
      '&:hover, &$focusVisible': {
        zIndex: 1,
        '& $imageBackdrop': {
          opacity: 0.25,
        },
        '& $imageMarked': {
          opacity: 0,
        },
        '& $imageTitle': {
          border: '4px solid currentColor',
        },
      },
    },
    focusVisible: {},
    imageButton: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'yellow'
    },
    imageSrc: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: 'cover',
      backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.black,
      opacity: 0.4,
      transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
      position: 'relative',
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
      height: 3,
      width: 18,
      backgroundColor: theme.palette.common.white,
      position: 'absolute',
      bottom: -2,
      left: 'calc(50% - 9px)',
      transition: theme.transitions.create('opacity'),
      },
    }));


const images = [
    {
      url: 'https://svgsilh.com/svg/1749345-2196f3.svg',
      title: 'PART1 ?',
      width: '100%',
      code : '/recruter'
    },
    {
      url: 'https://cdn.pixabay.com/photo/2014/05/02/21/50/laptop-336378__340.jpg',
      title: 'PART2 ?',
      width: '100%',
      code: '/developper',
    },
  ]

const Account = () => {
  
  const classes = useStyles();
  if (!isAuthenticated()) {
    login()
    return (
    <Loading />
    )
  }else{
  const user = getProfile()

    return (
      <>
      <section className={classes.root}>
        <Router>
            <Home path="/account/" user={user} />
        </Router> 
        <Divider/>
      </section>
      {images.map(image => (
        <ButtonBase 
          focusRipple
          key={image.title}
          className={classes.image}
          focusVisibleClassName={classes.focusVisible}
          style={{
            width: image.width,
          }}
        >
          <span
            className={classes.imageSrc}
            style={{
              backgroundImage: `url(${image.url})`,
            }}
          />
          <span className={classes.imageBackdrop} />
          <span className={classes.imageButton}>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              className={classes.imageTitle}
            
            >
              {/* <div role = "button"  tabIndex={0}  onClick={()=> {}}  onKeyDown={()=> navigate(`${image.code}`)}> */}
              <div role = "button"  tabIndex={0}  onClick={()=> navigate(image.code)}  onKeyDown={()=> navigate(image.code)}>
                {image.title}
              </div>
              <span className={classes.imageMarked} />
            </Typography>
          </span>
        </ButtonBase>
    ))} 
    <Container maxWidth="sm" style={{textAlign: 'center'}}> 
      <a style={{marginLeft:'1rem'}}
        href="#logout"
        onClick={e => {
          logout()
          e.preventDefault()
          navigate('/')
        }}
      >
        <ExitToAppIcon style={{ fontSize: '50', marginTop: '5%' }}  />
      </a>
    </Container>
    </>
    )
  }
}

export default Account
